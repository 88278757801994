<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import {required} from "vuelidate/lib/validators";
import {getUserParkings} from "@/services/parkings.service";
import {createPartner, deletePartner, editPartner, getPartner, getPartners} from "@/services/partner.service";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    hideModal() {
      this.partner = {
        username: "",
        parking_id: "",
        password: "",
      }
      this.submitted = false
      this.showmodal = false
      this.isCreateError = false
      this.createError = ""
    },
    getParkingOptions() {
      const parkingOptions = this.userParkings.map((parking) => {
        return {
          value: parking._id,
          text: parking.name
        }
      })

      return parkingOptions
    },
    async agregatePartnerData() {
      this.partnerData = []

      for(let parking of this.parkingOptions) {
        console.log(parking)
        const parkingPartners = await getPartners(parking.value)
        this.partnerData = [...parkingPartners.data.partners]
      }
    },
    async edit() {
      this.isEditError = false
      this.editError = ""
      const partnerData = this.partner
      partnerData.partner_id = this.partner._id

      const result = await editPartner(partnerData)
      if (result.status >= 400) {
        if (result.status === 400) {
          const errors = Object.values(result.errors)
          errors.forEach(error => this.editError += (this.editError === null) ? '': `${error[0]} <br/>` )
        } else {
          this.editError = result.errors.status
        }
        this.isEditError = true
      } else {
        await this.agregatePartnerData()
        this.edit_showmodal = false
      }
    },
    async loadEdit(partner_id) {
      const thePartner = await getPartner(partner_id)
      this.partner = thePartner.data.partner

      this.edit_showmodal = true
    },
    async create() {
      this.submitted = true
      this.isCreateError = false
      this.createError = ""
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('invalid')
        return
      } else {
        const result = await createPartner(this.partner)

        if (result.status >= 400) {
          if (result.status === 400) {
            const errors = Object.values(result.errors)
            errors.forEach(error => this.createError += (this.createError === null) ? '': `${error[0]} <br/>` )
          } else {
            this.createError = result.errors.status
          }
          this.isCreateError = true
        } else {
          await this.agregatePartnerData()
          this.showmodal = false
        }
      }

      this.submitted = false
      this.partner = {
        username: "",
        parking_id: "",
        password: "",
      }
    },
    async remove(id, parking_id) {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "Borrar un partner no es reversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Si, borrar!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.value) {
          await deletePartner(id, parking_id)
          await this.agregatePartnerData()
          Swal.fire("Borrado!", "El partner ha sido eliminado.", "success");
        }
      })
    },
    getParkingName(id) {
      const parking = this.userParkings.filter(parking => parking._id == id)
      if(parking.length > 0) {
        return parking[0].name
      }
      return ''
    },
    hideEditModal() {
      this.partner = {
        username: "",
        parking_id: "",
        password: "",
      }
      this.isEditError = false
      this.editError = ""
      this.edit_showmodal = false
    }
  },
  data() {
    return {
      title: 'Socios',
      items: [
        {
          text: "MaxParking"
        },
        {
          text: "Socios",
          active: true
        }
      ],
      editing_id: "",
      showmodal: false,
      edit_showmodal: false,
      isCreateError: false,
      isEditError: false,
      createError: "",
      editError: "",
      submitted: false,
      partner: {
        username: "",
        password: "",
        parking_id: ""
      },
      parkingOptions: [],
      partnerData: []
    }
  },
  validations: {
    partner: {
      username: { required },
      parking_id: { required },
      password: { required }
    },
  },
  async mounted() {
    this.userParkings = await getUserParkings()
    this.parkingOptions = this.getParkingOptions()
    await this.agregatePartnerData()
    console.log(this.partnerData)
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div>
                <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                  <i class="mdi mdi-plus mr-2"></i> Agregar Socio
                </a>
              </div>

              <div class="table-responsive mt-3">
                <table
                    class="table table-centered datatable dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                  <tr>
                    <th>Partner</th>
                    <th>Parqueo Asignado</th>
                    <th style="width: 120px;">Acción</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in partnerData" :key="index">
                    <td>{{item.username}}</td>
                    <td>{{getParkingName(item.parking_id)}}</td>
                    <td>
                      <a
                          @click="loadEdit(item._id)"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Editar Información"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                          :href="'/partners/' + item._id + '/statement'"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Estado de cuenta"
                      >
                        <i class="bx ri-file-paper-line"></i>
                      </a>

                      <a
                          @click="remove(item._id, item.parking_id)"
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Borrar"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- CREATE Modal -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="Agregar un socio"
        title-class="text-dark font-18"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
    >
      <b-alert
          v-model="isCreateError"
          class="mt-3"
          variant="danger"
          dismissible>
        <span v-html="createError"></span>
      </b-alert>
      <form @submit.prevent="create">
        <div class="form-group">
          <label for="username">Usuario Del Socio</label>
          <input
              id="operator_id"
              v-model="partner.username"
              type="text"
              class="form-control"
              placeholder="Usuario"
              :class="{ 'is-invalid': submitted && $v.partner.username.$error }"
          />
          <div
              v-if="submitted && !$v.partner.username.required"
              class="invalid-feedback"
          >El usuario del socio es requerido</div>
        </div>

        <div class="form-group">
          <label for="parking_id">Parqueo Asignado</label>
          <b-form-select
              v-model="partner.parking_id"
              :options="parkingOptions"
              :class="{ 'is-invalid': submitted && $v.partner.parking_id.$error }"></b-form-select>
          <div
              v-if="submitted && !$v.partner.parking_id.required"
              class="invalid-feedback"
          >El parqueo asignado es invalido</div>
        </div>

        <div class="form-group">
          <label for="password">Contraseña</label>
          <input
              id="password"
              v-model="partner.password"
              type="password"
              class="form-control"
              placeholder="Ingrese la contraseña intencional"
              :class="{ 'is-invalid': submitted && $v.partner.password.$error }"
          />
          <div
              v-if="submitted && !$v.partner.password.required"
              class="invalid-feedback"
          >La contraseña de socio es requerida</div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">Guardar</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end create modal -->


    <!-- EDIT Modal -->
    <b-modal
        id="modal-1"
        v-model="edit_showmodal"
        title="Editar un socio"
        title-class="text-dark font-18"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
    >
      <b-alert
          v-model="isEditError"
          class="mt-3"
          variant="danger"
          dismissible>
        <span v-html="editError"></span>
      </b-alert>
      <form @submit.prevent="edit">
        <div class="form-group">
          <label for="username">Usuario Del Socio</label>
          <input
              id="operator_id"
              v-model="partner.username"
              type="text"
              class="form-control"
              placeholder="Usuario"
              :class="{ 'is-invalid': submitted && $v.partner.username.$error }"
          />
          <div
              v-if="submitted && !$v.partner.username.required"
              class="invalid-feedback"
          >El usuario del socio es requerido</div>
        </div>

        <div class="form-group">
          <label for="parking_id">Parqueo Asignado</label>
          <b-form-select
              v-model="partner.parking_id"
              :options="parkingOptions"
              :class="{ 'is-invalid': submitted && $v.partner.parking_id.$error }"></b-form-select>
          <div
              v-if="submitted && !$v.partner.parking_id.required"
              class="invalid-feedback"
          >El parqueo asignado es invalido</div>
        </div>

        <div class="form-group">
          <label for="password">Contraseña</label>
          <input
              id="password"
              v-model="partner.password"
              type="password"
              class="form-control"
              placeholder="Ingrese la contraseña intencional"
              :class="{ 'is-invalid': submitted && $v.partner.password.$error }"
          />
          <div
              v-if="submitted && !$v.partner.password.required"
              class="invalid-feedback"
          >La contraseña de socio es requerida</div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">Guardar</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">Cancelar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end create modal -->
  </Layout>
</template>