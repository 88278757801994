const axios = require('axios')
const api_base = process.env.VUE_APP_API_BASE_URL

const createPartner = async (partner) => {
    const url = `${api_base}/v1/partners`

    return await post(url, partner)
}

const getPartners = async (parking_id) => {
    const url = `${api_base}/v1/partners/${parking_id}`

    return  await axios.get(url)
}

const getPartner = async(partner_id) => {
    const url = `${api_base}/v1/partners/get/${partner_id}`

    return  await axios.get(url)
}

const editPartner = async (partner) => {
    const url = `${api_base}/v1/partners`

    const result = await patch(url, partner)
        .catch(() => {
            console.log('ERROR UPDATING PARTNER')
            return {
                data: {
                    partner: {}
                }
            }
        })

    return result
}


const deletePartner = async (partner_id, parking_id) => {
    const url = `${api_base}/v1/partners`

    return await del(url, {
        data: {
            parking_id,
            partner_id,
        }
    }).catch((error) => {
        console.log('ERROR DELETING PARTNER')
        const retError = {
            errors: error.response.data
        }

        retError.status = error.response.status

        return retError
    })
}

const getPartnerUnsettledDiscounts = async (partner_id) => {
    const url = `${api_base}/v1/discounts/${partner_id}/unsettled`

    return  await axios.get(url)
}

const settleAccount = async  (partner_id) => {
    const url = `${api_base}/v1/discounts/${partner_id}`

    return await del(url, null)
}

const post = async (url, data) => {
    return await axios.post(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })
}

const del = async (url, data) => {
    const result = await axios.delete(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })

    return result
}

const patch = async (url, data) => {
    const result = await axios.patch(url, data)
        .catch((error) => {
            const retError = {
                errors: error.response.data
            }

            retError.status = error.response.status

            return retError
        })

    return result
}

module.exports = {
    createPartner,
    getPartners,
    deletePartner,
    getPartner,
    editPartner,
    getPartnerUnsettledDiscounts,
    settleAccount
}